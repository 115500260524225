/**
 * @generated SignedSource<<f01fa66d8d090f4c1a8834e4b00aa00a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HeroSingleLineText_contentBlock$data = {
  readonly text: string;
  readonly " $fragmentType": "HeroSingleLineText_contentBlock";
};
export type HeroSingleLineText_contentBlock$key = {
  readonly " $data"?: HeroSingleLineText_contentBlock$data;
  readonly " $fragmentSpreads": FragmentRefs<"HeroSingleLineText_contentBlock">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HeroSingleLineText_contentBlock",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    }
  ],
  "type": "HeroSingleLineTextBlock",
  "abstractKey": null
};

(node as any).hash = "2422edaf1e56064b537edf76c22b372a";

export default node;
