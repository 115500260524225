import type { FC } from 'react';
import styled from '@emotion/styled';
import { useFragment, graphql } from 'react-relay/hooks';
import { HeroColors } from '../hero-colors';
import type { HeroSingleLineText_contentBlock$key } from './__generated__/HeroSingleLineText_contentBlock.graphql';

const contentBlockFragment = graphql`
  fragment HeroSingleLineText_contentBlock on HeroSingleLineTextBlock {
    text
  }
`;

const Text = styled.p({
  textAlign: 'center',
  margin: 0,
  color: HeroColors.Text,
});

type HeroSingleLineTextComponentProps = {
  contentBlock: HeroSingleLineText_contentBlock$key;
};

const HeroSingleLineText: FC<HeroSingleLineTextComponentProps> = ({ contentBlock }) => {
  const { text } = useFragment(contentBlockFragment, contentBlock);
  return <Text>{text}</Text>;
};

export default HeroSingleLineText;
